import React from 'react';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GuardedRoutes from "../src/AuthGuard";
import Login from "./component/Login/Login";
import AdminDashboard from './component/AdminDashboard/AdminDashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admin/dashboard"
          element={<GuardedRoutes Component={AdminDashboard} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;